import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/ecommerce/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },

  {
    path: "/posts",
    name: "PostsIndex",
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/index"),
  },
  {
    path: "/posts/create",
    name: "PostsCreate", // all
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/create"),
  },
  {
    path: "/pages/:id",
    name: "PostsUpdate", // update
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/update"),
  },
  {
    path: "/posts/categories",
    name: "CategoriesIndex", // update
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/categories"),
  },
  {
    path: "/admin/pages",
    name: "Admin panel for page titles table", // CRUD
    meta: { title: "Admin panel for page titles table", authRequired: true },
    component: () => import("../views/admin/pages"),
  },
  {
    path: "/admin/navigations",
    name: "Admin panel for navigations table", // CRUD
    meta: { title: "Admin panel for navigations table", authRequired: true },
    component: () => import("../views/admin/navigations"),
  },
  {
    path: "/admin/sections",
    name: "Admin panel for sections table", // CRUD
    meta: { title: "Admin panel for sections table", authRequired: true },
    component: () => import("../views/admin/sections"),
  },
  {
    path: "/admin/posts",
    name: "Admin panel for posts table", // CRUD
    meta: { title: "Admin panel for posts table", authRequired: true },
    component: () => import("../views/admin/posts"),
  },
  {
    path: "/admin/features",
    name: "Admin panel for features table", // CRUD
    meta: { title: "Admin panel for features table", authRequired: true },
    component: () => import("../views/admin/features"),
  },
  {
    path: "/admin/categories",
    name: "Admin panel for categories table", // CRUD
    meta: { title: "Admin panel for categories table", authRequired: true },
    component: () => import("../views/admin/categories"),
  },
  {
    path: "/admin/statistics",
    name: "Admin panel for statistics table", // CRUD
    meta: { title: "Admin panel for statistics table", authRequired: true },
    component: () => import("../views/admin/statistics"),
  },
  {
    path: "/admin/awards",
    name: "Admin panel for awards table", // CRUD
    meta: { title: "Admin panel for awards table", authRequired: true },
    component: () => import("../views/admin/awards"),
  },
  {
    path: "/admin/testimonials",
    name: "Admin panel for testimonials table", // CRUD
    meta: { title: "Admin panel for testimonials table", authRequired: true },
    component: () => import("../views/admin/testimonials"),
  },
  {
    path: "/admin/accreditations",
    name: "Admin panel for accreditations table", // CRUD
    meta: { title: "Admin panel for accreditations table", authRequired: true },
    component: () => import("../views/admin/accreditations"),
  },
  {
    path: "/admin/courses",
    name: "Admin panel for courses table", // CRUD
    meta: { title: "Admin panel for courses table", authRequired: true },
    component: () => import("../views/admin/courses"),
  },
  {
    path: "/admin/objectives",
    name: "Admin panel for objectives table", // CRUD
    meta: { title: "Admin panel for objectives table", authRequired: true },
    component: () => import("../views/admin/objectives"),
  },
  {
    path: "/admin/teachers",
    name: "Admin panel for teachers table", // CRUD
    meta: { title: "Admin panel for teachers table", authRequired: true },
    component: () => import("../views/admin/teachers"),
  },
  {
    path: "/admin/footers",
    name: "Admin panel for footers table", // CRUD
    meta: { title: "Admin panel for footers table", authRequired: true },
    component: () => import("../views/admin/footers"),
  },
  {
    path: "/admin/faqs",
    name: "Admin panel for faqs table", // CRUD
    meta: { title: "Admin panel for faqs table", authRequired: true },
    component: () => import("../views/admin/faqs"),
  },
  {
    path: "/admin/reviews",
    name: "Admin panel for reviews table", // CRUD
    meta: { title: "Admin panel for reviews table", authRequired: true },
    component: () => import("../views/admin/reviews"),
  },


  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: "Signin Basic", authRequired: true,
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: "Signin Cover", authRequired: true,
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: "Signup Basic", authRequired: true,
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: "Signup Cover", authRequired: true,
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/auth/reset-pwd-basic',
    name: 'reset-pwd-basic',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/basic')
  },
  {
    path: '/auth/reset-pwd-cover',
    name: 'reset-pwd-cover',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/cover')
  },
  {
    path: '/auth/create-pwd-basic',
    name: 'create-pwd-basic',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/basic')
  },
  {
    path: '/auth/create-pwd-cover',
    name: 'create-pwd-cover',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/cover')
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/lockscreen-cover',
    name: 'lock-screen-cover',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/cover')
  },
  {
    path: '/auth/twostep-basic',
    name: 'twostep-basic',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/basic')
  }, {
    path: '/auth/twostep-cover',
    name: 'twostep-cover',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/cover')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
];